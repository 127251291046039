.slick-slide {
    transform: scale(0.8);
    transition: all 0.4s ease-in-out;
    padding: 20px 0;
}

.slick-slide img {
    max-width: 100%;
    transition: all 0.4s ease-in-out;
}

.slick-center {
    transform: scale(1);
}

.slick-slide>div div:focus-visible {
    outline: none;
}

.slick-list,
.slick-slider,
.slick-track {
    padding: 1.5rem 0;
}

.slick-slider,
.slick-track {
    padding-top: 0px;
}