.SortableItem {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  align-items: center;
  padding: 5px 10px;
  /* background-color: #fff;
  box-shadow: 0 0 0 calc(1px / var(--scale-x, 1)) rgba(63, 63, 68, 0.05),
    0 1px calc(3px / var(--scale-x, 1)) 0 rgba(34, 33, 81, 0.15);
  border-radius: calc(4px / var(--scale-x, 1));
  box-sizing: border-box; */
  list-style: none;
  color: #333;
  /* font-weight: 400;
  font-size: 1rem;
  font-family: sans-serif; */
}

.DragHandle {
  display: flex;
  width: 12px;
  padding: 15px;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  touch-action: none;
  cursor: var(--cursor, pointer);
  border: none;
  outline: none;
  appearance: none;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
}

.DragHandle:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.DragHandle:focus-visible {
  box-shadow: 0 0px 0px 2px #4c9ffe;
}

.DragHandle svg {
  flex: 0 0 auto;
  margin: auto;
  height: 100%;
  overflow: visible;
  fill: #919eab;
}

.notes__title {
  color: #fff;
  font-size: 38px;
  font-family: "Montserrat";
  font-weight: 400;
}
.notes {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(3, minmax(300px, 1fr));
}
@media screen and (max-width: 900px) {
  .notes {
      grid-template-columns: repeat(2, minmax(300px, 1fr));
  }
}
@media screen and (max-width: 500px) {
  .notes {
      grid-template-columns: repeat(1, minmax(300px, 1fr));
  }
}