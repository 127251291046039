/* src/ImageSlider.css */

.slider-container {
  display: flex;
  overflow-x: auto;
  gap: 20px;
  padding: 20px;
  scrollbar-width: thin;
}

.slider-item {
  flex: 0 0 auto;
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.image-container {
  position: relative;
}

.image-checkbox {
  position: absolute;
  right: 10px; /* Adjust based on your needs */
  top: 10px;
  z-index: 2;
  width: 24px; /* Bigger checkbox */
  height: 24px;
  cursor: pointer;
}

.checkbox-label {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.slider-item img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  display: block;
}

.rating {
  display: flex;
}

.star {
  color: grey;
}

.filled {
  color: gold;
}
