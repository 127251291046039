.note {
    background: rgba(12, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    box-shadow: inset -6px -4px 2px rgba(255, 255, 255, 0.03);
    padding: 15px;
    min-height: 140px;
    justify-content: space-between;
    word-wrap: break-word;
}

.note textarea {
    white-space: pre-wrap;
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    resize: none;
    font-size: 18px;
}

.note textarea:focus {
    outline: none;
}
